import React, { useState, useEffect } from "react";
import useTable, { TableFooter } from "../../hooks/useTable";
import { cn } from "../../utils/lib";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { GET_COMPANIES_URL, GET_TASK_URL, GET_USER_REMINDERS_URL } from "../../utils/URLS";
import axios from "axios";
import moment from "moment";
import { useUserContext } from "../../Context/UserContext";
import './dashboard.css'
import CompanyDetailsButton from "../Companies/CompanyDetailsButton";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import DateUpdateModal from "./ModaleChange";
import SeekSolutionApi from "../../utils/SeekSolutionApi";
import { toast } from 'react-toastify';
const filterCompanies = (data) => {
  return data.filter(company => {
      const daysLeftFields = [
          'days_left_next_business_registration_renewal_due',
          'days_left_profit_tax_return_deadline',
          'days_left_next_annual_return_due'
      ];

      // Check if any days left value is less than 5
      return daysLeftFields.some(field => company[field] !== null && company[field] < 5);
  });
}

const renderDeadlineParagraph = (daysLeft) => {
  return (
      <p className={`mb-1 text-left pr-4 pl-2 text-sm leading-none task-description ${
          daysLeft <= 0 ? 'bg-red-500 text-white' : 'bg-amber-500 text-white'
      }`}>
          {daysLeft <= 0 ? 
              `Overdue by ${Math.abs(daysLeft)} day(s)` :
              `Upcoming in ${daysLeft} day(s)`
          }
      </p>
  );
};

const getStatus = (dueDate) => {
  const now = moment();
  const due = moment(dueDate);

  const diffInDays = due.diff(now, 'days');

  if (diffInDays === 0) {
    return "upcoming";
  } else if (diffInDays === -1 || (diffInDays < 0 && diffInDays >= -15)) {
    return "overdue";
  } else {
    return "";
  }
};


export default function Dashboard() {
  SeekSolutionApi.setToken(localStorage.getItem("token"))
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [user] = useUserContext();
  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [task, setTask] = useState([]);
  const [loading, setLoading] = useState(true);
  const { slice, range } = useTable(reminders, page, 20);
  const [admin, setAdmin] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [serversNames, setServiceName] = useState([]);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState('');
  const [nextAnnualReturnDate, setNextAnnualReturnDate] = useState('');
  const [nextBusinessRegistrationRequest, setNextBusinessRegistrationRequest] = useState('');

  const data = null
  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(GET_COMPANIES_URL, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      // setCompanies(response.data);
      setFilteredCompanies(response.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const updateCompanyDetail = async (id, items) => {
    try {
        console.log("updateTask", id, items);
        await SeekSolutionApi.Company.update(id, items)
    } catch (error) {
  
    }
  }
  

  const handleIconClick = (item) => {
    setModalData(item.id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };
  const handleSave = async () => {
    if (modalData) {
      let item_Data = {
                        "next_annual_return_due": nextAnnualReturnDate,
                        "next_business_registration_renewal_due": nextBusinessRegistrationRequest
                    }
      try {
        await updateCompanyDetail(modalData, item_Data);
        toast.success('Company details updated successfully');
      } catch (error) {
        // Handle the error
        console.error('Error updating company detail:', error);
        toast.error('Failed to update company details');
      }
    }
    // Handle the save logic
    closeModal();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const response = await axios.get(GET_COMPANIES_URL, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      setCompanies(filterCompanies(response.data));
      setFilteredCompanies(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (user.groups.includes("administrator")) {
        setAdmin(true);
      }
    }

    async function fetchReminderData() {
      const response = await axios.get(GET_USER_REMINDERS_URL, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      setReminders(response?.data);
      // setFilteredCompanies(response.data);
      setLoading(false);
    }

    async function fetchCompanyData() {
      const response = await axios.get(GET_COMPANIES_URL, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      setCompanies((response?.data));
      
      setLoading(false);
    }

    async function fetchTaskData() {
      setLoading(true)
      const response = await axios.get(GET_COMPANIES_URL, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const compId = response.data.sort((a, b)=> {
        const companyNameA = a.company_name.toLowerCase();
        const companyNameB = b.company_name.toLowerCase();

        if (companyNameA < companyNameB) return -1;
        if (companyNameA > companyNameB) return 1;
        return 0;
      })
      compId.forEach(async (item) => {
        const res = await axios.get(`${GET_TASK_URL}?company_id=${item.id}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        });
        const showTask = res.data.filter( (item) => item.status !== "Done")
        
        setTask((prev) => [...prev, ...showTask])
      })
      
      setLoading(false);
    }



    //fetchTaskData();
    fetchReminderData();
    fetchCompanyData();
    

  }, [user]);


  const handleFilterMonthChange = (value) => {
    setSelectedMonth(value);

    const selectedMonth = value

    const data = companies.filter(item => {
        // Extract and parse the month from item.next_annual_return_due
        const itemMonth = parseInt(item.next_annual_return_due.split('-')[1], 10);
        const next_business_registration_renewal_due = parseInt(item.next_business_registration_renewal_due.split('-')[1], 10);
        
        // Compare the month with the selected month
        return itemMonth == selectedMonth || next_business_registration_renewal_due == selectedMonth ;
    });
    console.log(data , "datadatadatadatadata")
    // Perform any additional actions when the month is changed
    if( data?.[0]){
      setCompanies(data);
    }else{
      alert("Data Not Found")
      // setSelectedDate('')
      setSelectedMonth('')
    }
  };

const handleYearChange = (year) => {
  setSelectedYear(year);
  
  // const data = companies.filter(item => item.profit_tax_return_deadline == year)
  const data = companies.filter(item => {
    // Extract and parse the month from item.next_annual_return_due
    const itemYear = parseInt(item.next_business_registration_renewal_due.split('-')[2], 10);
    
    // Compare the month with the selected month
    return itemYear == year;
});
  if( data?.[0]){
    setCompanies(data);
  }else{
    alert("Data Not Found")
    // setSelectedYear('')
    fetchCompanies()
  }

};

const renderYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear - 15; year <= currentYear + 15; year++) {
    years.push(year);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};


  // const handleFilterDateChange = (date) => {
  //   setFilterDate(date);
  // };
  return (
    <div className="flex flex-col gap-5">
      <div className="relative mt-16 rounded-xl bg-white p-6 shadow">
        <div className="-mt-16 mb-5 rounded-xl bg-golden px-4 py-6 text-2xl font-semibold text-white shadow-md">
          <p>Welcome</p>
        </div>
        <p className="text-gray-700">{user?.user.name} !</p>
      </div>
     
      
       
      <div className="relative mt-16 rounded-xl bg-white p-6 shadow">
        <div className="-mt-16 mb-5 rounded-xl bg-golden px-4 py-6 text-2xl font-semibold text-white shadow-md">
          <p>Upcoming Reminders</p>
        </div>
        {loading ? (
          <div className="flex items-center justify-center py-5">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="py-3 text-left text-xs text-gray-400">
                    COMPANY NAME
                  </th>
                  <th className="py-3 text-left text-xs text-gray-400">
                    REMINDER
                  </th>
                  <th className="py-3 text-xs text-gray-400">STATUS</th>
                  <th className="py-3 text-xs text-gray-400">DUE DATE</th>
                </tr>
              </thead>
              <tbody>
                {reminders.length > 0 ? (reminders?.map((item, key) => (
                  <tr key={key} className="border-w border-gray-300">
                    <td className="py-4 pl-10">
                      <div>
                        <p className="mb-1 font-semibold leading-none text-gray-700">
                          {item.company_name}

                        </p>
                        <p className="text-xs leading-none text-gray-500">
                          {item.assigned_officer?.email}
                        </p>
                      </div>
                    </td>
                    <td className="py-4">
                      <div>
                        <p className="mb-1 text-sm font-semibold leading-none text-gray-600">
                          {item?.reminder?.name}
                        </p>
                      </div>
                    </td>
                    <td className="py-4">
                      <center>
                        <span
                          className={cn(
                            "rounded-xl p-2 text-xs font-medium uppercase text-white",
                            {
                              "bg-[#54AA57]": getStatus(item?.due_date) === "upcoming",
                              "bg-[#F89607]": getStatus(item?.due_date) === "overdue",
                            },
                          )}
                        >
                          {getStatus(item?.due_date)}
                        </span>
                      </center>
                    </td>
                    <td className="py-4">
                      <center>
                        <span
                          className={cn(
                            "font-xs p-2 text-sm font-medium capitalize text-gray-600",
                            {
                              "text-[#F89607]": getStatus(item?.due_date) === "overdue",
                            },
                          )}
                        >
                          {item?.due_date}
                        </span>
                      </center>
                    </td>
                  </tr>
                ))) : (<><tr className="border-w border-gray-300">
                  <td className="py-4" colSpan="4">
                    <div>
                      <p className="mb-1 leading-none text-gray-700">
                        No upcoming reminders
                      </p>
                    </div>
                  </td></tr></>)}
              </tbody>
            </table>
            {reminders.length > 0 && <TableFooter
              range={range}
              slice={slice}
              setPage={setPage}
              page={page}
            />}
          </>
        )}
      </div>

      <div className="relative mt-16 rounded-xl bg-white p-6 shadow">
        <div className="-mt-16 mb-5 rounded-xl bg-golden px-4 py-6 text-2xl font-semibold text-white shadow-md">
          <p>Tasks</p>
        </div>
        {loading ? (
          <div className="flex items-center justify-center py-5">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="py-3 text-left text-xs text-gray-400">
                    COMPANY
                  </th>
                  <th className="py-3 text-left text-xs text-gray-400">
                    NAME
                  </th>
                  <th className="py-3 text-left text-xs text-gray-400">
                    STATUS
                  </th>
                  <th className="py-3 text-left text-xs text-gray-400">
                    PRIORITY
                  </th>
                  <th className="py-3 text-left text-xs text-gray-400 min-width">
                    DUE DATE
                  </th>
                  {/* <th className="py-3 text-left text-xs text-gray-400">
                    ASSIGNED TO
                  </th> */}
                  {/* <th className="py-3 text-left text-xs text-gray-400">
                    COMPANY
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {task.length > 0 ? (task?.map((item, key) => (
                  <tr key={key} className="border-w border-b-2 border-gray-300 task-table">
                    <td className="py-4">
                        <p className="mb-1 text-left pr-4 text-sm leading-none text-black task-description text-gray-700">
                        <CompanyDetailsButton company={filteredCompanies.find(comp => comp.company_name === item.company_name)} />
                          {item?.company_name}

                          
                        </p>
                    </td>
                    <td className="min-width">
                        <p className="mb-1 text-left pr-4 text-sm leading-none text-black task-description text-gray-700">
                          {item.title}

                        </p>
                    </td>
                    <td className="">
                        <p className="mb-1 text-left pr-4 text-sm leading-none text-black task-description text-gray-700">
                          {item?.status}
                        </p>
                    </td>
                    <td className="">
                        <p className="mb-1 text-left pr-4 text-sm leading-none text-black task-description text-gray-700">
                          {item?.priority}
                        </p>
                    </td>
                    <td className="">
                        <p className="mb-1 text-left pr-4 text-sm leading-none text-black task-description text-gray-700">
                          {item?.due_date}
                        </p>
                    </td>
                    {/* <td className="py-4">
                        <p className="mb-1 text-left pr-4 text-sm leading-none text-black task-description text-gray-700">
                          {item?.assigned_to_email}
                        </p>
                    </td> */}
                  
                  </tr>
                ))) : (<><tr className="border-w border-gray-300">
                  <td className="py-4" colSpan="4">
                    <div>
                      <p className="mb-1 leading-none text-gray-700">
                        No Task Available
                      </p>
                    </div>
                  </td></tr></>)}
              </tbody>
            </table>
            <DateUpdateModal
              isModalOpen={isModalOpen}
              nextAnnualReturnDate={nextAnnualReturnDate}
              nextBusinessRegistrationRequest={nextBusinessRegistrationRequest}
              setNextAnnualReturnDate={setNextAnnualReturnDate}
              setNextBusinessRegistrationRequest={setNextBusinessRegistrationRequest}
              handleSave={handleSave}
              closeModal={handleCloseModal}
            />
          </>
        )}
      </div>
    </div>
  );
}